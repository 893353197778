import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import PageContainer from "../components/Layout/PageContainer/PageContainer";
import { Img } from "../components/UI/Img.styled";
import notFoundImg from "../images/pages/404.png";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageContainer
      title="NOT FOUND"
      subtitle="you hit the road that doesn't exist =("
      textContainer
    >
      <Img src={notFoundImg} alt=""></Img>
    </PageContainer>
  </Layout>
);

export default NotFoundPage;
